import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyledLink, Grid } from './Catalog.styled';
import { routeToModelsByBrand } from '../../redux/filter/filter';

const CatalogGrid = () => {
  const dispatch = useDispatch();
  const { filteredLogoBrand } = useSelector((state) => state.filter);

  const handleSelectBrand = (value) => {
    dispatch(routeToModelsByBrand(value));
  };

  return (
    <Grid>
      {filteredLogoBrand.map((brand) => {
        const lowerCasePath = brand.name.replace(/\s/g, '').toLowerCase();

        return (
          <StyledLink
            to={`/catalog/${lowerCasePath}`}
            key={brand.name}
            onClick={() => handleSelectBrand(lowerCasePath)}
          >
            <img src={brand.logo} alt={brand.name} />

            <p>{brand.name}</p>
          </StyledLink>
        );
      })}
    </Grid>
  );
};

export default CatalogGrid;
