import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getRequestParams, resourceUrl } from '../../api/getRequestParams';

/* -------------------------- достаём все продукты -------------------------- */
export const fetchRecommended = createAsyncThunk(
  'recommended/fetchRecommended',
  async ({ id, page }, { rejectWithValue }) => {
    try {
      const params = getRequestParams();

      const response = await axios.get(
        `${resourceUrl}/wc/v3/products?_fields=id,name,images,categories,slug&category=${id}&per_page=10&page=${page}`,
        { params }
      );

      const data = response.data.map(({ id, name, images, categories, slug }) => {
        const processedImages = images.map(({ id, src }) => ({ id, src }));
        const recommended = 332; //id категории рекомендуемые из WP
        const category = categories.map(({ id, slug }) => ({ id, slug })).filter(({ id }) => id !== recommended);

        return {
          id,
          name,
          images: processedImages,
          categories: category,
          currentSlug: slug,
        };
      });

      return data;
    } catch (error) {
      console.error('Ошибка при загрузке товаров:', error);
      return rejectWithValue(error.response?.data || 'Произошла ошибка при загрузке товаров');
    }
  }
);
