export const CTALOG_PLANNER = [
  {
    id: '1',
    img: '/images/accessories/eva/plannerMINI/1.webp',
    imgList: [
      '/images/accessories/eva/plannerMINI/1.webp',
      '/images/accessories/eva/plannerMINI/2.webp',
      '/images/accessories/eva/plannerMINI/3.webp',
    ],
    title: 'Сумка-органайзер у багажник EVACAR EVA матеріал',
    strongText: 'Наші органайзери з EVA матеріалу',
    text: '- це чудове рішення для зберігання речей в автомобілі. EVA (етиленвінілацетат) - це легкий, міцний і водонепроникний матеріал, який забезпечує надійний захист ваших речей від вологи та пошкоджень. Органайзери з EVA матеріалу відрізняються високою зносостійкістю і легкістю у догляді. Вони ідеально підходять для зберігання різноманітних предметів, забезпечуючи порядок і зручність у подорожі та зробить їх комфортнішими.',
    sizes: [
      { label: '35 * 30 * 25', value: '35*30*25', price: 980 },
      { label: '60 * 20 * 25', value: '60*20*25', price: 1420 },
    ],
    colors: [
      { label: 'Сірий', value: 'gr' },
      { label: 'Чорний', value: 'bk' },
      { label: 'Коричневий', value: 'br' },
      { label: 'Бежевий', value: 'bg' },
      { label: 'Бордовий', value: 'bd' },
      { label: 'Темно-синій', value: 'dbl' },
      { label: 'Хакі', value: 'ml' },
    ],
    material: 'eva',
    page: 'planner',
  },
  {
    id: '2',
    img: '/images/accessories/eva/plannerNEW/1.webp',
    imgList: [
      '/images/accessories/eva/plannerNEW/1.webp',
      '/images/accessories/eva/plannerNEW/2.webp',
      '/images/accessories/eva/plannerNEW/3.webp',
      '/images/accessories/eva/plannerNEW/4.webp',
      // '/images/accessories/eva/plannerNEW/5.webp',
    ],
    title: 'Сумка-органайзер у багажник EVACAR EVA матеріал',
    strongText: 'Наші органайзери з EVA матеріалу',
    text: '- це чудове рішення для зберігання речей в автомобілі. EVA (етиленвінілацетат) - це легкий, міцний і водонепроникний матеріал, який забезпечує надійний захист ваших речей від вологи та пошкоджень. Органайзери з EVA матеріалу відрізняються високою зносостійкістю і легкістю у догляді. Вони ідеально підходять для зберігання різноманітних предметів, забезпечуючи порядок і зручність у вашому автомобілі. Обираючи наші органайзери, ви отримуєте надійний і функціональний продукт, який полегшить ваші подорожі та зробить їх комфортнішими.',
    sizes: [
      { label: '27 * 30 * 44', value: '27*30*44', price: 1600 },
      { label: '36 * 36 * 40', value: '36*36*40', price: 1750 },
      { label: '30 * 29 * 79', value: '30*29*79', price: 2000 },
    ],
    colors: [
      { label: 'Сірий', value: 'gr' },
      { label: 'Чорний', value: 'bk' },
      { label: 'Коричневий', value: 'br' },
      { label: 'Бежевий', value: 'bg' },
      { label: 'Бордовий', value: 'bd' },
      { label: 'Темно-синій', value: 'dbl' },
      { label: 'Хакі', value: 'ml' },
    ],
    material: 'eva',
    page: 'planner',
  },

  {
    id: '3',
    img: '/images/accessories/eco/whiteBull/1.webp',
    imgList: [
      '/images/accessories/eco/whiteBull/1.webp',
      '/images/accessories/eco/whiteBull/2.webp',
      '/images/accessories/eco/whiteBull/3.webp',
      '/images/accessories/eco/whiteBull/4.webp',
    ],
    title: 'Сумка-органайзер у багажник EVACAR ECO матеріал',
    strongText: 'Наші органайзери з ЕКО шкіри',
    text: '- це ідеальне рішення для підтримання порядку в автомобілі. Вони виготовлені з високоякісної еко шкіри, яка поєднує в собі стильний вигляд, довговічність і екологічність. Органайзери допоможуть зручно зберігати ваші речі, забезпечуючи швидкий доступ до всього необхідного під час подорожей. Кожен виріб продуманий до дрібниць, щоб забезпечити максимальний комфорт і функціональність. Обираючи наші органайзери, ви отримуєте якісний продукт, який стане незамінним помічником у вашому авто.',
    sizes: [
      { label: '30 * 30 * 48', value: '30*30*48', price: 1850 },
      { label: '30 * 30 * 60', value: '30*30*60', price: 2050 },
      { label: '30 * 30 * 80', value: '30*30*80', price: 2250 },
    ],
    colors: [
      { label: 'Чорний з чорною ниткою', value: 'bk_bk-th' },
      { label: 'Чорний з червоною ниткою', value: 'bk_rd-th' },
      { label: 'Чорний з синьою ниткою', value: 'bk_bl-th' },
      { label: 'Бежевий', value: 'bg' },
      { label: 'Коричневий', value: 'br' },
    ],
    material: 'eco',
    page: 'planner',
  },
];

// материал

export const CTALOG_MAT = [
  {
    id: '1',
    img: '/images/accessories/mat/matImages/bk.webp',
    imgList: [
      '/images/accessories/mat/matImages/bk.webp',
      '/images/accessories/mat/matImages/bd.webp',
      '/images/accessories/mat/matImages/bg.webp',
      '/images/accessories/mat/matImages/bl.webp',
      '/images/accessories/mat/matImages/br.webp',
      '/images/accessories/mat/matImages/dbl.webp',
      '/images/accessories/mat/matImages/gr.webp',
      '/images/accessories/mat/matImages/koyot.webp',
      '/images/accessories/mat/matImages/kr.webp',
      '/images/accessories/mat/matImages/lgr.webp',
      '/images/accessories/mat/matImages/ml.webp',
      '/images/accessories/mat/matImages/or.webp',
      '/images/accessories/mat/matImages/rd.webp',
      '/images/accessories/mat/matImages/4.webp',
      '/images/accessories/mat/matImages/5.webp',
      '/images/accessories/mat/matImages/6.webp',
    ],

    title: 'Лист EVA для автокилимів 245 х 145 см',
    text1:
      'EVA матеріал відповідає європейським стандартам, виготовлений з первинної сировини, не має запаху навіть після розпакування чи термообробки.',
    text2:
      'Велика кольорова палітра та три види текстур (ромб, сота, крапля) дозволяють обрати оптимальний варіант для будь-якого автомобіля.',
    text3:
      'Матеріал підходить для виготовлення автокилимків для легкових авто, вантажівок, автобусів, катерів, човнів, яхт, вертольотів та інших транспортних засобів.',

    type: [
      { label: 'Ромб', value: 'romb', img: '/images/accessories/mat/typeMat/romb.jpg' },
      { label: 'Сота', value: 'sota', img: '/images/accessories/mat/typeMat/sota.jpg' },
      { label: 'Крапля', value: 'kaplya', img: '/images/accessories/mat/typeMat/kaplya.jpg' },
    ],
    colors: [
      { label: 'Чорний', value: 'bk', img: '/images/accessories/mat/matImages/bk.webp' },
      { label: 'Бордовий', value: 'bd', img: '/images/accessories/mat/matImages/bd.webp' },
      { label: 'Бежевий', value: 'bg', img: '/images/accessories/mat/matImages/bg.webp' },
      { label: 'Синій', value: 'bl', img: '/images/accessories/mat/matImages/bl.webp' },
      { label: 'Коричневий', value: 'br', img: '/images/accessories/mat/matImages/br.webp' },
      { label: 'Темно-синій', value: 'dbl', img: '/images/accessories/mat/matImages/dbl.webp' },
      { label: 'Сірий', value: 'gr', img: '/images/accessories/mat/matImages/gr.webp' },
      { label: 'Койот', value: 'coy', img: '/images/accessories/mat/matImages/koyot.webp' },
      { label: 'Кремовий', value: 'kr', img: '/images/accessories/mat/matImages/kr.webp' },
      { label: 'Світло-сірий', value: 'lgr', img: '/images/accessories/mat/matImages/lgr.webp' },
      { label: 'Хакі', value: 'ml', img: '/images/accessories/mat/matImages/ml.webp' },
      { label: 'Помаранчевий', value: 'or', img: '/images/accessories/mat/matImages/or.webp' },
      { label: 'Червоний', value: 'rd', img: '/images/accessories/mat/matImages/rd.webp' },
    ],
    price: 1800,
    page: 'mat',
  },
];

export const All_PRODUCT = [...CTALOG_PLANNER, ...CTALOG_MAT];
