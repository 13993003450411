export const resourceUrl = process.env.REACT_APP_BASE_URL;
export const getRequestParams = () => {
  const consumer_key = process.env.REACT_APP_CONSUMER_KEY;
  const consumer_secret = process.env.REACT_APP_CONSUMER_SECRET_KEY;
  return {
    consumer_key,
    consumer_secret,
    hide_empty: true,
    context: 'view',
  };
};
