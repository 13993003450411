export const COLOR_MAP = {
  Бежевий: 'bg',
  Білий: 'wh',
  Бордовий: 'br',
  Жовтий: 'yl',
  Зелений: 'gn',
  Койот: 'coy',
  Коричневий: 'br',
  Кремовий: 'kr',
  Помаранчевий: 'or',
  'Світло-коричневий': 'lbr',
  'Світло-сірий': 'lgr',
  Синій: 'bl',
  Сірий: 'gr',
  'Темно-синій': 'dbl',
  Фіолетовий: 'pur',
  Хакі: 'ml',
  Червоний: 'rd',
  Чорний: 'bk',
};

export const TYPE_MATERIAL_KEYS = {
  'Автотканина + Антара': 'AtA',
  ЕКОматериал: 'eco',
  'Екошкіра + Антара': 'ecoA',
  'Екошкіра + Автотканина': 'ecoAt',
  'Екошкіра + Перфорація': 'ecoP',
  ЕВАматериал: 'eva',
  Ворс: 'pile',
};

export const TYPE_PRESS_KEYS = {
  Ромб: 'romb',
  Сота: 'sota',
  Крапля: 'kaplya',
};
