/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
// import { CTALOG_PLANNER } from '../../data/dbAccessories';
import { t } from 'i18next';

const initialState = {
  plannerId: '',
  imgAcc: '',
  title: '',
  typeM: '', //тип материала ева\эко
  size: '', //размер аксесуара
  color: '', //ЦВЕТ
  // catalog: CTALOG_PLANNER,
  sizeOptions: [],
  colorOptions: [],

  price: 0,
};

const plannerSlice = createSlice({
  name: 'planner',
  initialState,

  reducers: {
    popelateInitialState: (state, { payload }) => {
      const { id, value, material, img, attributes } = payload;

      state.plannerId = id;
      state.imgAcc = img;
      // state.title = title;
      state.typeM = material;
      state.price = t(`accessories.planner.${id}.${value}`);

      state.color = 'bk';

      if (attributes && attributes.length > 0 && attributes[0].options) {
        const newOptions = attributes[0].options.map((option) => ({
          label: option.replace(/\*/g, ' * '),
          value: option,
        }));

        state.size = newOptions[0].value;
        state.sizeOptions = newOptions || [];
      }

      if (attributes && attributes.length > 0 && attributes[1].options) {
        const newOptions = attributes[1].options.map((option) => ({
          value: option,
        }));

        state.colorOptions = newOptions || [];
      }

      // state.sizeOptions = sizes || [];
      // state.colorOptions = colors || [];
    },

    selectTypeM: (state, { payload }) => {
      state.typeM = payload;
    },

    selectSize: (state, { payload }) => {
      const selectedSize = state.sizeOptions.find((size) => size.value === payload);

      if (selectedSize) {
        state.size = payload;
        state.price = t(`accessories.planner.${state.plannerId}.${selectedSize.value}`);
        // state.price = selectedSize.price;
      }
    },

    selectColor: (state, { payload }) => {
      state.color = payload;
    },

    cleanState: (state) => {
      state.imgAcc = '';
      state.title = '';
      state.typeM = '';
      state.price = 0;
      state.size = '';
      state.color = '';
    },
  },
});

export const { popelateInitialState, selectTypeM, selectSize, selectColor, cleanState } = plannerSlice.actions;

export const plannerReducer = plannerSlice.reducer;
/* ---------------------------- распарсить обьект --------------------------- */
function decoder(name, value) {
  const originalObject = JSON.parse(JSON.stringify(value));
  console.log(name, originalObject);
}
