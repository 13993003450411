import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { resourceUrl, getRequestParams } from '../../api/getRequestParams';

/* ---------------------------- список категории ---------------------------- */
export const fetchCategories = createAsyncThunk('categories/fetchCategories', async (_, { rejectWithValue }) => {
  try {
    const params = getRequestParams();

    const response = await axios.get(
      `${resourceUrl}/wc/v3/products/categories?_fields=id,name,slug,image&per_page=20`,
      { params }
    );

    const data = response.data.map(({ id, name, slug, image }) => ({
      id,
      name,
      slug,
      image: { id: image?.id || 0, src: image?.src || '' },
    }));

    return data;
  } catch (error) {
    console.error('Ошибка при загрузке товаров:', error);
    return rejectWithValue(error.response?.data || 'Произошла ошибка при загрузке товаров');
  }
});
