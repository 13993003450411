/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { fetchRecommended } from './recommendedApi';

const initialState = {
  recommendedWP: [],
  isLoading: false,
};

const recommendedSlice = createSlice({
  name: 'recommended',
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(fetchRecommended.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchRecommended.fulfilled, (state, { payload }) => {
        state.recommendedWP = payload;
      })
      .addCase(fetchRecommended.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  setSearchQuery,
  inputClear,
  loadMoreProducts,
  sortPriceMax,
  sortPriceMin,
  sortReset,
  clearFilteredProducts,
  setLoading,
} = recommendedSlice.actions;
export const recommendedReducer = recommendedSlice.reducer;

/* ---------------------------- распарсить обьект --------------------------- */
function decoder(name, value) {
  const originalObject = JSON.parse(JSON.stringify(value));
  console.log(name, originalObject);
}
