import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getRequestParams, resourceUrl } from '../../api/getRequestParams';

/* -------------------------- достаём продукт по ID ------------------------- */
export const fetchProduct = createAsyncThunk('product/fetchProduct', async (id, { rejectWithValue }) => {
  try {
    const params = getRequestParams();
    const response = await axios.get(
      `${resourceUrl}/wc/v3/products/${id}?_fields=id,name,images,attributes,description,short_description,slug,sku,has_options,price`,
      { params }
    );

    const extractFields = (product) => {
      const {
        id,
        name,
        images = [],
        attributes = [],
        description,
        short_description,
        slug,
        sku,
        has_options,
        price,
      } = product;

      const processedImages = images.map(({ id, src }) => ({ id, src }));
      const processedAttributes = attributes.map(({ id, name, options }) => ({ id, name, options }));

      return {
        id,
        name,
        images: processedImages,
        attributes: processedAttributes,
        description,
        short_description,
        slug,
        sku,
        has_options,
        price,
      };
    };

    return extractFields(response.data);
  } catch (error) {
    console.error('Ошибка при загрузке товаров:', error);
    return rejectWithValue(error.response?.data || 'Произошла ошибка при загрузке товаров');
  }
});

/* -------------------------- достаём вариации продукта с фотками ------------------------- */
export const fetchVariations = createAsyncThunk('product/fetchVariations', async (id, { rejectWithValue }) => {
  try {
    const params = getRequestParams();
    const response = await axios.get(`${resourceUrl}/wc/v3/products/${id}/variations?_fields=id,name,image`, {
      params,
    });

    const data = response.data.map(({ id, name, image }) => ({ id, name, src: image?.src || '' }));
    return data;
  } catch (error) {
    console.error('Ошибка при загрузке товаров:', error);
    return rejectWithValue(error.response?.data || 'Произошла ошибка при загрузке товаров');
  }
});
