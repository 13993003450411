/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { CTALOG_MAT } from '../../data/dbAccessories';
import { t } from 'i18next';

const initialState = {
  matId: '',
  imgMat: '',
  title: '',
  typeM: '', //тип материала ромб\сота\капля
  color: '', //ЦВЕТ
  // catalog: CTALOG_MAT,
  colorOptions: [],
  count: 1,

  basePrice: 0, // Базовая цена за единицу товара
  price: 0, // Начальная цена

  priceList: [], //масив цен за количество единиц
};

// Функция для расчета базовой цены
const calculateBasePrice = (count, priceList) => {
  for (let i = 0; i < priceList.length; i++) {
    if (count >= priceList[i].minCount) {
      return priceList[i].price;
    }
  }
  return priceList[priceList.length - 1].price;
};

const matSlice = createSlice({
  name: 'mat',
  initialState,

  reducers: {
    popelateMatInitialState: (state, { payload }) => {
      const { id, variantName, img, attributes, name, priceList, basePrice, price } = payload;

      state.matId = id;
      state.imgMat = img;
      // state.title = name;
      state.typeM = variantName; //тип материала
      state.color = 'bk';
      state.basePrice = basePrice;
      state.price = price;
      state.priceList = priceList;

      if (attributes && attributes.length > 0 && attributes[1].options) {
        const newOptions = attributes[1].options.map((option) => {
          const color = CTALOG_MAT[0].colors.find(({ value }) => option === value);

          return {
            value: option,
            img: color ? color.img : null,
          };
        });

        state.colorOptions = newOptions || [];
      }
    },

    selectTypeM: (state, { payload }) => {
      const { value, img } = payload;

      state.typeM = value;
      state.imgMat = img;
    },

    selectColor: (state, { payload }) => {
      state.color = payload;
    },

    decrement: (state) => {
      state.count = state.count > 1 ? state.count - 1 : 1;
      state.basePrice = calculateBasePrice(state.count, state.priceList); // Обновляем базовую цену
      state.price = state.basePrice * state.count; // Пересчитываем общую цену
    },

    increment: (state) => {
      state.count = state.count + 1;
      state.basePrice = calculateBasePrice(state.count, state.priceList); // Обновляем базовую цену
      state.price = state.basePrice * state.count; // Пересчитываем общую цену
    },

    setCount: (state, { payload }) => {
      state.count = payload.value > 0 ? payload.value : 1; // Обновление значения
      state.basePrice = calculateBasePrice(state.count, state.priceList); // Обновляем базовую цену
      state.price = state.basePrice * state.count; // Пересчитываем общую цену
    },

    cleanState: (state) => {
      state.imgMat = '';
      state.title = '';
      state.typeM = '';
      state.color = '';
      state.count = 1;
      state.price = t('accessories.mat.price');
    },
  },
});

export const { popelateMatInitialState, selectTypeM, decrement, increment, setCount, selectColor, cleanState } =
  matSlice.actions;

export const matReducer = matSlice.reducer;

/* ---------------------------- распарсить обьект --------------------------- */
function decoder(name, value) {
  const originalObject = JSON.parse(JSON.stringify(value));
  console.log(name, originalObject);
}
