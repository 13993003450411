import { createSlice } from '@reduxjs/toolkit';

import { fetchProduct, fetchVariations } from './productApi';

const initialState = {
  currentProductWP: { id: 0, name: '', images: [], attributes: [], description: '', slug: '', sku: '' },
  variationsWP: [],
  hasOptions: null,
  isLoading: false,
};

const productSlice = createSlice({
  name: 'product',
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(fetchProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProduct.fulfilled, (state, { payload }) => {
        state.currentProductWP = payload;
        state.hasOptions = payload.has_options;
        state.isLoading = false;
      })
      .addCase(fetchProduct.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchVariations.fulfilled, (state, { payload }) => {
        const variations = payload;

        state.variationsWP = variations;
      });
  },
});

export const productReducer = productSlice.reducer;
