import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledCatalog = styled.section`
  padding: 60px 0 0 0;
  text-align: center;
`;

export const SelectBox = styled.div`
  position: relative;
  display: inline-block;
  margin-bottom: 50px;

  ::after {
    content: '';
    width: 12px;
    height: 12px;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    fill: red;
    background: url('/images/icons/arrow.svg') no-repeat center/contain;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const StyledSelect = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;

  width: 620px;
  height: 50px;
  padding: 0 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: white;
  border: none;
  border-radius: 8px;
  background: ${(p) => p.theme.colors.modalColor};
  cursor: pointer;

  color: rgb(194, 194, 194);

  :focus {
    box-shadow: 0 0 5px rgba(255, 0, 0, 0.5);
    outline: none;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  justify-items: center;

  @media screen and (max-width: 1260px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (max-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 652px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 0 60px 0;
  }
`;

export const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 190px;
  height: 190px;
  text-align: center;

  :hover {
    img {
      box-shadow: 0px 0px 20px 0px rgba(255, 0, 0, 0.5);
    }
  }

  img {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;

    background-color: ${(p) => p.theme.colors.modalColor};
    border-radius: 12px;
    transition: box-shadow 0.3s ease;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;
