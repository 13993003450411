import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Это по умолчанию использует localStorage для веба

import { filterReducer } from './filter/filter';
import { constructorRugReducer } from './constructor/constructor';
import { basketReducer } from './basket/basket';
import { matReducer } from './mat/mat';
import { coverReducer } from './cover/cover';
import { categoriesReducer } from './categories/categories';
import { productsReducer } from './products/products';
import { productReducer } from './product/product';
import { plannerReducer } from './planner/planner';
import { generalProductCardReducer } from './generalProductCard/generalProductCard';
import { recommendedReducer } from './recommended/recommended';

// Создаем persistConfig
const persistConfig = {
  key: 'root', // Ключ для хранения состояния
  storage,
  whitelist: [
    'filter',
    'constructorRug',
    'basket',
    'planner',
    'mat',
    'cover',
    'generalProductCard',
    'categories',
    'products',
    'recommended',
    'product',
  ], // Перечень редьюсеров, которые нужно сохранять
};

// Комбинируем редьюсеры
const rootReducer = combineReducers({
  filter: filterReducer,
  constructorRug: constructorRugReducer,
  basket: basketReducer,
  planner: plannerReducer,
  mat: matReducer,
  cover: coverReducer,
  categories: categoriesReducer,
  products: productsReducer,
  recommended: recommendedReducer,
  product: productReducer,
  generalProductCard: generalProductCardReducer,
});

// Оборачиваем rootReducer в persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
