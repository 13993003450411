/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  coverId: '',
  imgCover: '',
  title: '',
  typeM: '', //тип материала ева\эко

  sideСolor: [],
  centerColors: [], // для центра
  insertColors: [], // для вставки
  threadColors: [], // для нитки

  selectedSideСolor: '', //цвет боковин
  selectedCenterColor: '', //цвет цента
  selectedInsertColor: '', //цвет вставки
  selectedThreadColor: '', //цвет нитки

  center: '',
  insert: '',

  price: 0,
};

const distributionOfOptions = (attributes) => {
  const newOptions = attributes?.options.map((option) => ({
    value: option,
  }));

  return newOptions || [];
};

const coverSlice = createSlice({
  name: 'cover',
  initialState,
  reducers: {
    popelateCoverInitialState: (state, { payload }) => {
      const { id, price, attributes, title, img } = payload;
      state.coverId = id;
      state.imgCover = img;
      state.title = title;
      state.typeM = attributes[4]?.options[0];
      state.price = price;

      const center = distributionOfOptions(attributes[1])[0]?.value === 'false';
      const insert = distributionOfOptions(attributes[2])[0]?.value === 'false';

      state.center = !center;
      state.insert = !insert;

      state.sideСolor = distributionOfOptions(attributes[0]);
      state.centerColors = distributionOfOptions(attributes[1]);
      state.insertColors = distributionOfOptions(attributes[2]);
      state.threadColors = distributionOfOptions(attributes[3]);

      state.selectedSideСolor = state.sideСolor[0]?.value;
      state.selectedCenterColor = !center && state.centerColors[0]?.value;
      state.selectedInsertColor = !insert && state.insertColors[0]?.value;
      state.selectedThreadColor = state.threadColors[0]?.value;
    },

    // боковины
    setSelectedSideСolor: (state, { payload }) => {
      state.selectedSideСolor = payload;
    },

    // центр
    setSelectedCenterColor: (state, { payload }) => {
      state.selectedCenterColor = payload;
    },

    // вставка
    setSelectedInsertColor: (state, { payload }) => {
      state.selectedInsertColor = payload;
    },

    // нитки
    setSelectedThreadColor: (state, { payload }) => {
      state.selectedThreadColor = payload;
    },

    cleanState: (state) => {
      state.imgAcc = '';
      state.title = '';
      state.typeM = '';
      state.price = 0;
      state.center = '';
      state.insert = '';
    },
  },
});

export const {
  popelateCoverInitialState,
  setSelectedSideСolor,
  setSelectedCenterColor,
  setSelectedInsertColor,
  setSelectedThreadColor,
  cleanState,
} = coverSlice.actions;

export const coverReducer = coverSlice.reducer;
/* ---------------------------- распарсить обьект --------------------------- */
function decoder(name, value) {
  const originalObject = JSON.parse(JSON.stringify(value));
  console.log(name, originalObject);
}
