// схема ковриков в бусик
export const SCHEME_VAN = [
  {
    img: '/images/constructor/options/van/k5-100x100.png',
    value: 'k5',
    title: '1 ряд',
    price: 1680,
  },
  {
    img: '/images/constructor/options/van/k6-100x100.png',
    value: 'k6',
    title: '1, 2 ряди',
    price: 3025,
  },
  {
    img: '/images/constructor/options/van/k7-100x100.png',
    value: 'k7',
    title: '1, 2, 3 ряди',
    price: 3865,
  },
  {
    img: '/images/constructor/options/van/k8-100x100.png',
    value: 'k8',
    title: 'Весь салон',
    price: 4660,
  },
  {
    img: '/images/constructor/options/van/k9-100x100.png',
    value: 'k9',
    title: 'Багажник',
    price: 1705,
  },
  {
    img: '/images/constructor/options/van/k10-100x100.png',
    value: 'k10',
    title: '3 ряд',
    price: 1680,
  },
];

// схема ковриков в легковую
export const SCHEME_CAR = [
  {
    img: '/images/constructor/options/car/k1-100x100.png',
    value: 'k1',
    title: '1 ряд',
    price: 1435,
  },
  {
    img: '/images/constructor/options/car/k2-100x100.png',
    value: 'k2',
    title: '1, 2 ряди',
    price: 2245,
  },
  {
    img: '/images/constructor/options/car/k3-100x100.png',
    value: 'k3',
    title: 'Весь салон',
    price: 3465,
  },
  {
    img: '/images/constructor/options/car/k4-100x100.png',
    value: 'k4',
    title: 'Багажник',
    price: 1950,
  },
];

// цены на бортики и класические коврики только для легковых
export const ADDITIONAL_PRICES = {
  classic: [1395, 1899, 3430, 1750],
  bort5: [1520, 2385, 3585],
  bort10: [1625, 2665, 3870],
};

// ТИП МАТЕРИАЛА
export const TYPE_OF_MATERIAL = [
  {
    img: '/images/constructor/options/romb-100x100.jpg',
    value: 'romb',
    title: 'Ромб',
  },
  // {
  //   img: '/images/constructor/options/sota-100x100.jpg',
  //   value: 'sota',
  //   title: 'Сота',
  // },
];

// тип коврика
export const TYPE_OF_RUG = [
  {
    value: 'Зd', //Зd=> русская буква
    title: '3D килим',
  },
  {
    value: 'classic',
    title: 'Класичний',
  },
];

// размер бортика
export const TYPE_OF_BOART = [
  {
    value: 'bort5',
    title: 'З бортами до 5 см',
  },
  {
    value: 'bort10',
    title: 'З бортами до 10 см',
  },
];

// цвет коврика
export const RUG_COLOR = [
  {
    img: '/images/constructor/options/colorRug/r_bk-100x100.jpg',
    value: ['r_bk', 's_bk'],
    title: 'Чорний',
  },
  {
    img: '/images/constructor/options/colorRug/r_gr-100x100.jpg',
    value: ['r_gr', 's_gr'],
    title: 'Сірий',
  },
  {
    img: '/images/constructor/options/colorRug/r_br-100x100.jpg',
    value: ['r_br', 's_br'],
    title: 'Коричневий',
  },
  {
    img: '/images/constructor/options/colorRug/r_bg-100x100.jpg',
    value: ['r_bg', 's_bg'],
    title: 'Бежевий',
  },
  {
    img: '/images/constructor/options/colorRug/r_kr-100x100.jpg',
    value: ['r_kr', 's_kr'],
    title: 'Кремовий',
  },
  {
    img: '/images/constructor/options/colorRug/r_rd-100x100.jpg',
    value: ['r_rd', 's_rd'],
    title: 'Червоний',
  },
  {
    img: '/images/constructor/options/colorRug/r_bl-100x100.jpg',
    value: ['r_bl', 's_bl'],
    title: 'Синій',
  },
  {
    img: '/images/constructor/options/colorRug/r_ml-100x100.jpg',
    value: ['r_ml', 's_ml'],
    title: 'Хакі',
  },
  {
    img: '/images/constructor/options/colorRug/r_bd-100x100.jpg',
    value: ['r_bd', 's_bd'],
    title: 'Бордовий',
  },
  {
    img: '/images/constructor/options/colorRug/r_dbl-100x100.jpg',
    value: ['r_dbl', 's_dbl'],
    title: 'Темно-сіиній',
  },
];

// цвет кантика
export const EDGE_COLOR = [
  {
    img: '/images/constructor/options/colorKant/bk-100x100.jpg',
    value: 'bk',
    title: 'Чорний',
  },
  {
    img: '/images/constructor/options/colorKant/gr-100x100.jpg',
    value: 'gr',
    title: 'Сірий',
  },
  {
    img: '/images/constructor/options/colorKant/br-100x100.jpg',
    value: 'br',
    title: 'Коричневий',
  },
  {
    img: '/images/constructor/options/colorKant/pur-100x100.jpg',
    value: 'pur',
    title: 'Фіолетовий',
  },
  // {
  //   img: "/images/constructor/options/colorKant/bg-100x100.jpg",
  //   value: "bg",
  //   title: "Бежевий",
  // },
  {
    img: '/images/constructor/options/colorKant/kr-100x100.jpg',
    value: 'kr',
    title: 'Кремовий',
  },
  {
    img: '/images/constructor/options/colorKant/rd-100x100.jpg',
    value: 'rd',
    title: 'Червоний',
  },
  {
    img: '/images/constructor/options/colorKant/bl-100x100.jpg',
    value: 'bl',
    title: 'Синій',
  },
  {
    img: '/images/constructor/options/colorKant/gn-100x100.jpg',
    value: 'gn',
    title: 'Зелений',
  },
  {
    img: '/images/constructor/options/colorKant/or-100x100.jpg',
    value: 'or',
    title: 'Помаранчевий',
  },
  {
    img: '/images/constructor/options/colorKant/yl-100x100.jpg',
    value: 'yl',
    title: 'Жовтий',
  },
  {
    img: '/images/constructor/options/colorKant/ml-100x100.jpg',
    value: 'ml',
    title: 'Хакі',
  },
  {
    img: '/images/constructor/options/colorKant/bd-100x100.jpg',
    value: 'bd',
    title: 'Бордовий',
  },
  {
    img: '/images/constructor/options/colorKant/dbl-100x100.jpg',
    value: 'dbl',
    title: 'Темно-синій',
  },
];

//количество шыльдиков
export const NAMEPLATE_PRICE = [
  { value: 'item0', label: 'Обрати', price: 0 },
  { value: 'item1', label: 'Килимок водія | 1 шт. 150 ₴', price: 150 },
  { value: 'items2', label: 'Передні | 2 шт. 300 ₴', price: 300 },
  { value: 'items4', label: 'Передні та задні | 4 шт. 500 ₴', price: 500 },
  {
    value: 'items5',
    label: 'Передні, задні, багажник | 5 шт. 625 ₴',
    price: 625,
  },
];
