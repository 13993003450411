export const theme = {
  colors: {
    bgColor: '#000000',
    black: '#000000',
    white: 'white',
    accentColor: 'rgb(238, 28, 37)',
    secondaryColor: '#777777',
    lightGrey: '#C2C2C2',
    gradient: 'linear-gradient(180.00deg, rgb(39, 43, 52),rgb(238, 28, 37) 100%)',
    modalColor: 'rgb(39, 43, 52)',
    shadowColor: '0px 0px 20px 0px rgba(255, 0, 0, 0.5)',
  },

  radii: ['3px', '8px', '12px', '50%'],
  breakpoints: {
    xs: '0',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1400px',
  },
  fonts: {
    body: '"Montserrat", sans-serif',
    heading: '"Poppins", sans-serif',
  },

  fontWeights: {
    regular: 400,
    medium: 500,
    SemiBold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
  },

  fontSizes: {
    fsConstrH2: ' clamp(1.125rem, 0.5561rem + 1.8205vw, 2rem)',
    fsClampH2: 'clamp(1.5rem, 2vw + 1rem, 2rem)',
    fsClampH3: 'clamp(2.25rem, 0.0625rem + 7vw, 3.6rem)',
    fs11: '11px',
    fs12: '12px',
    fs14: '14px',
    fs16: '16px',
    fs20: '20px',
    fs23: '23px',
    fs24: '24px',
    fs32: '32px',
    fs36: '36px',
    fs46: '46px',
    fs64: '64px',
  },

  lineHeights: {
    lh20: '20px',
    lh24: '24px',
    lh39: '39px',
  },
};
