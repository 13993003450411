import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getRequestParams, resourceUrl } from '../../api/getRequestParams';

/* -------------------------- достаём все продукты -------------------------- */
export const fetchProducts = createAsyncThunk(
  'products/fetchProducts',
  async ({ id, page, signal }, { rejectWithValue }) => {
    try {
      const params = getRequestParams();

      // достаю продукты согласно id категории, в количестве 100 единиц
      const response = await axios.get(
        `${resourceUrl}/wc/v3/products?_fields=id,name,description,price,images,attributes,slug,sku,status&category=${id}&per_page=100&page=${page}`,
        { params, signal }
      );
      const total = response.headers['x-wp-total'];
      const data = response.data.map(({ id, name, description, price, images, attributes, slug, sku, status }) => {
        const processedImages = images.map(({ id, src }) => ({ id, src }));

        const processedAttributes = attributes.map(({ id, name, options }) => ({ id, name, options }));

        return {
          id,
          name,
          description,
          price,
          images: processedImages,
          attributes: processedAttributes,
          slug,
          sku,
          status,
        };
      });

      return { data, total: parseInt(total, 10) };
    } catch (error) {
      console.error('Ошибка при загрузке товаров:', error);
      return rejectWithValue(error.response?.data || 'Произошла ошибка при загрузке товаров');
    }
  }
);
